/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import Feed, { UpdatedFeed } from 'src/Component/Feed'
import { useLazyGetPrivateFeedQuery } from 'src/api/feed'
import ReportFeedMenu from '../../Component/ReportFeed/ReportFeedMenu'
import ReportFeedModal from '../../Component/ReportFeed/ReportFeedModal'

import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'

import InfiniteScroll from 'react-infinite-scroll-component'
import { Feed as FeedType } from 'src/api/feed/types'
import { Speciality } from 'src/api/createFeed/types'

type PrivateFeeddProps = {
  searchValue: string;
  specialities?: Speciality[]; // Optional array of Speciality
  sortBy?: string; // Optional string value
}

const PrivateFeed = ({ searchValue, specialities, sortBy }: PrivateFeeddProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { userInfo } = useSelector((state: RootState) => state.auth)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [reportFeedId, setReportFeedId] = useState<number>(0)
  const [authorId, setAuthorId] = React.useState<number>(0)

  const [paginatedData, setPaginatedData] = useState<FeedType[]>([])
  const [metaData, setMetaData] = useState({
    hasMore: false,
  })

  const pageRef = useRef<number>(1)
  const [fetchFeeds] = useLazyGetPrivateFeedQuery()

  const handlePopoverMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    feedId: number,
    authorId: number,
  ) => {
    setAnchorEl(event.currentTarget)
    setReportFeedId(feedId)
    setAuthorId(authorId)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

 

  const fetchFeedData = (isSearchResult = false, resetData = false): void => {
    if (isSearchResult) {
      pageRef.current = 1
    }
    dispatch(startPageLoader())
    fetchFeeds({
      search: searchValue,
      page: pageRef.current,
      speciality: specialities,
      sortOrder:sortBy
    })
      .unwrap()
      .then((res) => {
        setMetaData({
          hasMore: !!res.next,
        })
        if (isSearchResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
        } else {
          if (resetData) {
            setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
          } else {
            setPaginatedData([
              ...JSON.parse(JSON.stringify(paginatedData)),
              ...JSON.parse(JSON.stringify(res.results)),
            ])
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const fetchFirstPage = () => {
    pageRef.current = 1
    fetchFeedData(false, true)
    console.log('fetchFirstPage',paginatedData)
  }

  const fetchData = (isSearchResult = false): void => {
    if (isSearchResult) {
      pageRef.current = 1
    }
    dispatch(startPageLoader())
    fetchFeeds({
      search: searchValue,
      page: pageRef.current,
      speciality: specialities,
      sortOrder:sortBy
    })
      .unwrap()
      .then((res) => {
        setMetaData({
          hasMore: !!res.next,
        })
        if (isSearchResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.results)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleUpdateFeed = (updatedFeed: UpdatedFeed) => {
    const newState = paginatedData.map((feed) => {
      if (feed.id === updatedFeed.id) {
        feed.is_reacted = updatedFeed.isReacted
        feed.is_saved = updatedFeed.isSaved
        feed.like_count = updatedFeed.likeCount
      }
      return feed
    })
    setPaginatedData(JSON.parse(JSON.stringify(newState)))
  }

  useEffect(() => {
    // on search
    fetchData(true)
  }, [searchValue, sortBy, specialities])

  useEffect(() => {
    // fetch feeds on page load
    setPaginatedData([])
    pageRef.current = 1
    fetchData()
    fetchFirstPage()
  }, [])

  if (paginatedData.length === 0) {
    return (
      <Box textAlign='center'>
        <Typography variant='h5'> No feed found </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <InfiniteScroll
        dataLength={paginatedData.length}
        next={loadNextPage}
        hasMore={metaData.hasMore}
        loader={
          <Typography variant='subtitle1' textAlign='center'>
            {' '}
            loading more feeds..{' '}
          </Typography>
        }
      >
        {paginatedData.map((feed) => {
          return (
            <Feed
              onUpdateFeed={handleUpdateFeed}
              key={feed.id}
              id={feed.id}
              author={{
                id: feed.author.id,
                name: feed.author.name,
                profilePic: feed.author.profile_picture,
              }}
              isPrivateForAllConnections={feed.is_public_for_all_connections}
              isPrivateForSelectedConnections={feed.is_public_for_added_connections}
              images={feed.images}
              videos={feed.videos}
              likeCount={feed.like_count}
              createdAt={feed.created_at}
              isReacted={feed.is_reacted}
              isSaved={feed.is_saved}
              title={feed.title}
              description={feed.details}
              tags={feed.tags}
              isPhotoBlur={false}
              isChatVisible={
                !!feed.is_connected && feed.author.id !== userInfo?.id && !feed.is_anonymous
              }
              isLongDescription={feed.is_long_description}
              sections={feed.sections}
              headerMenu={
                <ReportFeedMenu
                  onMenuOpen={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handlePopoverMenuOpen(e, feed.id, feed?.author?.id)
                  }
                />
              }
            />
          )
        })}
      </InfiniteScroll>

      <ReportFeedModal
        isPopoverOpen={!!anchorEl}
        onPopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        reportFeedId={reportFeedId}
        authorId={authorId}
        refetchData={fetchFirstPage}
      />
    </Box>
  )
}

export default PrivateFeed
