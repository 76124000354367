/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Feed, { UpdatedFeed } from '../Feed'

import { Feed as FeedType } from 'src/api/feed/types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLazyGetUserLikedFeedsQuery } from 'src/api/userProfile'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { useLazyGetPublicFeedQuery } from 'src/api/feed'
const LikedPosts = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [paginatedData, setPaginatedData] = useState<FeedType[]>([])
  const [metaData, setMetaData] = useState({
    hasMore: true,
  })

  const pageRef = useRef<number>(1)
  // const [fetchFeeds] = useLazyGetUserLikedFeedsQuery()
  const [fetchFeeds] = useLazyGetPublicFeedQuery()
  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

  const fetchData = (isSearchResult = false): void => {
    if (isSearchResult) {
      pageRef.current = 1
    }
    dispatch(startPageLoader())
    fetchFeeds({
      page: pageRef.current,
    })
      .unwrap()
      .then((res) => {
        setMetaData({
          hasMore: !!res.next,
        })
        if (isSearchResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.results)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleUpdateFeed = (updatedFeed: UpdatedFeed) => {
    const newState = paginatedData.map((feed) => {
      if (feed.id === updatedFeed.id) {
        feed.is_reacted = updatedFeed.isReacted
        feed.is_saved = updatedFeed.isSaved
        feed.like_count = updatedFeed.likeCount
      }
      return feed
    })
    setPaginatedData(JSON.parse(JSON.stringify(newState)))
  }

  useEffect(() => {
    // fetch feeds on page load
    pageRef.current = 1
    fetchData()
  }, [])

  if (paginatedData.length === 0) {
    return (
      <Box textAlign='center' p={3}>
        <Typography variant='h5'> No feed found </Typography>
      </Box>
    )
  }
  return (
    <Box>
      <InfiniteScroll
        dataLength={paginatedData.length}
        next={loadNextPage}
        hasMore={metaData.hasMore}
        loader={
          <Typography variant='subtitle1' textAlign='center'>
            {' '}
            loading more feeds..{' '}
          </Typography>
        }
      >
        {paginatedData.map((feed) => {
          if (feed?.is_reacted) {
            return (
              <Feed
                onUpdateFeed={handleUpdateFeed}
                key={feed.id}
                id={feed.id}
                author={{
                  id: feed.author.id,
                  name: feed.author.name,
                  profilePic: feed.author.profile_picture,
                }}
                isPrivateForAllConnections={feed.is_public_for_all_connections}
                isPrivateForSelectedConnections={feed.is_public_for_added_connections}
                images={feed.images}
                videos={feed.videos}
                likeCount={feed.like_count}
                createdAt={feed.created_at}
                isReacted={feed.is_reacted}
                isSaved={feed.is_saved}
                title={feed.title}
                description={feed.details}
                tags={feed.tags}
                isPhotoBlur={feed.is_graphic}
                isChatVisible={false}
                isLongDescription={feed.is_long_description}
                sections={feed.sections}
              />
            )
          }
        })}
      </InfiniteScroll>
    </Box>
  )
}

export default LikedPosts
