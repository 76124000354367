/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import Feed, { UpdatedFeed } from 'src/Component/Feed'
import { useLazyGetMyPublicFeedsQuery } from 'src/api/feed'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import FeedMenu from './FeedMenu'
import FeedMenuActions from './FeedMenuActions'
import { Feed as FeedType } from 'src/api/feed/types'
import InfiniteScroll from 'react-infinite-scroll-component'
type FeedListdProps = {
  searchValue: string
}

const PublicFeedList = ({ searchValue }: FeedListdProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [FeedId, setFeedId] = React.useState<number>(0)

  const [paginatedData, setPaginatedData] = useState<FeedType[]>([])
  const [metaData, setMetaData] = useState({
    hasMore: true,
  })

  const pageRef = useRef<number>(1)
  const [fetchFeeds] = useLazyGetMyPublicFeedsQuery()

  const handlePopoverMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, feedId: number) => {
    setAnchorEl(event.currentTarget)
    setFeedId(feedId)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

  const fetchData = (isSearchResult = false, resetData = false): void => {
    if (isSearchResult) {
      pageRef.current = 1
    }
    dispatch(startPageLoader())
    fetchFeeds({
      search: searchValue,
      page: pageRef.current,
    })
      .unwrap()
      .then((res) => {
        setMetaData({
          hasMore: !!res.next,
        })
        if (isSearchResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
        } else {
          if (resetData) {
            setPaginatedData([...JSON.parse(JSON.stringify(res.results))])
          } else {
            setPaginatedData([
              ...JSON.parse(JSON.stringify(paginatedData)),
              ...JSON.parse(JSON.stringify(res.results)),
            ])
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  const handleUpdateFeed = (updatedFeed: UpdatedFeed) => {
    const newState = paginatedData.map((feed) => {
      if (feed.id === updatedFeed.id) {
        feed.is_reacted = updatedFeed.isReacted
        feed.is_saved = updatedFeed.isSaved
        feed.like_count = updatedFeed.likeCount
      }
      return feed
    })
    setPaginatedData(JSON.parse(JSON.stringify(newState)))
  }

  const fetchFirstPage = () => {
    pageRef.current = 1
    fetchData(false, true)
  }

  useEffect(() => {
    // on search
    fetchData(true)
  }, [searchValue])

  useEffect(() => {
    // fetch feeds on page load
    fetchFirstPage()
  }, [])

  if (paginatedData.length === 0) {
    return (
      <Box textAlign='center'>
        <Typography variant='h5'> No feed found </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <InfiniteScroll
        dataLength={paginatedData.length}
        next={loadNextPage}
        hasMore={metaData.hasMore}
        loader={
          <Typography variant='subtitle1' textAlign='center'>
            {' '}
            loading more feeds..{' '}
          </Typography>
        }
      >
        {paginatedData.map((feed) => {
          return (
            <Feed
              onUpdateFeed={handleUpdateFeed}
              key={feed.id}
              id={feed.id}
              author={{
                id: feed.author.id,
                name: feed.author.name,
                profilePic: feed.author.profile_picture,
              }}
              isPrivateForAllConnections={feed.is_public_for_all_connections}
              isPrivateForSelectedConnections={feed.is_public_for_added_connections}
              images={feed.images}
              videos={feed.videos}
              likeCount={feed.like_count}
              createdAt={feed.created_at}
              isReacted={feed.is_reacted}
              isSaved={feed.is_saved}
              title={feed.title}
              description={feed.details}
              tags={feed.tags}
              isPhotoBlur={false}
              isChatVisible={false}
              isLongDescription={feed.is_long_description}
              sections={feed.sections}
              headerMenu={
                <FeedMenu
                  onMenuOpen={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handlePopoverMenuOpen(e, feed.id)
                  }
                />
              }
            />
          )
        })}
      </InfiniteScroll>

      <FeedMenuActions
        isPopoverOpen={!!anchorEl}
        onPopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
        feedId={FeedId}
        refetchData={fetchFirstPage}
      />
    </Box>
  )
}

export default PublicFeedList
